import React from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { getHistory } from '../../utils/history.util';
import { GetAmountWithOutDecimals } from '../functions/common';
import { PlayerTypeV2Enum } from '../../enum/player-v2/PlayerTypeV2Enum';
export interface UserIconPopoverExternalActionProps {
  logoutAction: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    marginTop: '.5rem',
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '375px',
    width: '100%',
    // padding: '1rem',
    flexDirection: 'column',
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -15,
      left: '50%',
      width: 15,
      height: 15,
      backgroundColor: '#212222',
      boxShadow: theme.shadows[1],
      transform: 'translate(-50%, 50%) rotate(134deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },

  // Header
  header: {
    backgroundColor: 'black', // 707070
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  headerBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTop: {},
  logoutBtn: {
    float: 'right',
  },

  // Balance Menu
  balanceMenu: {
    paddingBottom: '10px',
    margin: '15px 15px 0',
    borderBottom: '2px solid rgba(255,255,255,.1)',
  },
  totalBalanceBg: {
    padding: '5px 10px',
    backgroundColor: 'rgba(255,255,255,.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  balanceMenuBodyItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#c1c1c1',
    paddingRight: '15px',
    paddingLeft: '15px',
  },

  // Footer
  balanceMenuFooter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '15px',
  },
  balanceMenuFooterLinks: {
    display: 'flex',
    padding: '15px 15px 0',
  },
  balanceMenuFooterLinksLeft: {
    display: 'flex',
    padding: '0 5px',
    alignItems: 'center',
    flexDirection: 'column',
    borderRight: '2px solid rgba(255,255,255,.1)',
  },
  balanceMenuFooterLinksRight: {
    display: 'flex',
    padding: '0 5px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  footerLink: {
    margin: '5px 0',
    padding: '1px 10px',
    fontSize: '.9em',
    textDecoration: 'none',
    color: 'rgba(255,255,255,.8)',
    cursor: 'pointer',
  },
  footerAction: {
    width: '90%',
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    height: '2px',
    backgroundColor: '#a1a1a1',
    marginTop: '10px',
    marginBottom: '10px',
  },
  textColorPrimary: {
    color: '#c8a964',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  profileIconButton: {
    boxShadow: '0px 0px 1px 1px #fff',
    animation: '$glowShadow 1.5s linear infinite alternate;',
    backgroundColor: '#121212',
    // marginLeft: '.75rem',
    marginLeft: '1.2rem',
    marginRight: '1.2rem',
    width: 40,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
    },
  },
  '@keyframes glowShadow': {
    to: {
      boxShadow: '0px 0px 12px 1px #fff',
    },
  },
  shrinkLogo: {
    maxHeight: '30px',
    maxWidth: '30px',
    color: theme.palette.secondary.main,
  },
}));

export interface UserIconProps {
  user: any;
  userBalance: any;
  setCashierDialogAction: (data: any) => void;
}

export const UserIconPopover = ({ user, userBalance, setCashierDialogAction }: UserIconProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {/* <FontAwesomeIcon icon={['fad', 'user']} /> */}
      {/* <FontAwesomeIcon icon={['fad', 'crown']} style={{ fontSize: '1.2rem' }} /> */}

      <IconButton color='primary' className={classes.profileIconButton} edge={false} onClick={handleClick}>
        {/* <FontAwesomeIcon icon={['fad', 'user']} /> */}
        {/* <FontAwesomeIcon icon={['fad', 'crown']} style={{ fontSize: '1.2rem' }} /> */}
        <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
      >
        {/* My Account Header */}
        <div className={classes.header}>
          {/* <div className={classes.headerTop}>
            <Typography variant='h6'>{user.username}</Typography>
          </div> */}
          <div className={classes.headerBottom}>
            <Typography variant='h6'>{user.username}</Typography>

            <Button
              variant='outlined'
              className={classes.logoutBtn}
              size='small'
              onClick={() => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                location.href = '/';
              }}
            >
              Log Out
            </Button>
          </div>
        </div>

        {/* Balance Menu Section */}
        <div className={classes.balanceMenu}>
          {/* Total Balance */}
          <div className={classes.totalBalanceBg}>
            <div>
              <Typography variant='h6'>
                {user?.player_type_id === 1 ? 'Available Balance' : 'Current Balance'}
                <br />
              </Typography>
            </div>
            <div>
              <Typography variant='h6'>
                ${GetAmountWithOutDecimals(user?.player_type_id === 1 ? userBalance.RealAvailBalance : userBalance.CurrentBalance)}
              </Typography>
            </div>
          </div>

          {user?.player_type_id === 2 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
              <div>
                <Typography variant='h6'>
                  Available Balance
                  <br />
                </Typography>
              </div>
              <div>
                <Typography variant='h6'>${GetAmountWithOutDecimals(userBalance.RealAvailBalance)}</Typography>
              </div>
            </div>
          )}

          {/* Cash Balance */}
          <div className={classes.balanceMenuBodyItem}>
            <Typography variant='button'>Pending Bets</Typography>

            <Link
              className={classes.footerLink}
              onClick={() => {
                getHistory().push('/pending-bets');
                setAnchorEl(null);
              }}
            >
              <Typography style={{ fontStyle: 'italic' }} color='primary'>
                See Details
              </Typography>
            </Link>
            <span>${GetAmountWithOutDecimals(userBalance.AmountAtRisk)} </span>
          </div>
          {/* {user?.player_type_id !== 2 && (
            <>
              <div className={classes.balanceMenuBodyItem}>
                <Typography variant='button'>
                  Total Balance
                  <br />
                  <span style={{ fontSize: '12px', fontWeight: 'bold', fontStyle: 'italic' }}>(Cash + Pending)</span>
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  ${userBalance && <>{GetAmountWithOutDecimals(userBalance.CurrentBalance)} USD</>}
                </div>
              </div>
              <div className={classes.divider}></div>
            </>
          )} */}
          {/* Free Play Balance */}
          <div className={classes.textColorPrimary}>
            <Typography variant='button'>Bonus</Typography>
            <span>${GetAmountWithOutDecimals(userBalance.FreePlayAmount)}</span>
          </div>
        </div>

        <div className={classes.balanceMenuFooter}>
          {/* <div className={classes.footerAction} style={{ marginBottom: '3%' }}>
            <Button
              variant='outlined'
              size='large'
              color='primary'
              onClick={() => {
                getHistory().push('/bracket');
                setAnchorEl(null);
              }}
            >
              <img style={{ width: '25px', height: '25px' }} src={`/images/icons/bracketIcon.png`} />
              World Cup Bracket
            </Button>
          </div> */}
          {/* this is the contest/bracket button */}
          {/* <div className={classes.footerAction} style={{ marginBottom: '3%' }}>
            <Button
              variant='outlined'
              size='large'
              color='primary'
              onClick={() => {
                getHistory().push('/bracket');
                setAnchorEl(null);
              }}
            >
              Contest
            </Button>
          </div> */}
          <div className={classes.footerAction}>
            <Button
              variant='contained'
              size='large'
              color='primary'
              onClick={() => {
                setCashierDialogAction({ isShow: true, type: 'cashier' });
                setAnchorEl(null);
              }}
            >
              Deposit Now
            </Button>
          </div>

          <div className={classes.balanceMenuFooterLinks}>
            <div className={classes.balanceMenuFooterLinksLeft}>
              <Link
                className={classes.footerLink}
                onClick={() => {
                  getHistory().push('/player-history');
                  setAnchorEl(null);
                }}
              >
                Player History
              </Link>
              <Link
                className={classes.footerLink}
                onClick={() => {
                  getHistory().push('/bonus-history');
                  setAnchorEl(null);
                }}
              >
                Bonus History
              </Link>
            </div>
            <div className={classes.balanceMenuFooterLinksRight}>
              {user?.player_type_id !== PlayerTypeV2Enum.AFFILIATE && (
                <Link
                  className={classes.footerLink}
                  onClick={() => {
                    getHistory().push('/refer-a-friend');
                    setAnchorEl(null);
                  }}
                >
                  Refer-a-Friend
                </Link>
              )}
              <Link
                className={classes.footerLink}
                onClick={() => {
                  getHistory().push('/profile');
                  setAnchorEl(null);
                }}
              >
                Profile Settings
              </Link>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};
